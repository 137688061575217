import {Helmet} from "react-helmet"
import {SignupForm} from "../components/signup-form"
import {createAppStoreLink} from "../helpers/app-store"
import Apple from "../icons/apple.svg"
import PlayCircled from "../icons/play-circled.svg"
import "../styles/index.scss"

function Index() {
  return (
    <main>
      <Helmet>
        <title>FocusLite - website blocker for Mac</title>
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>

      <div className="hero">
        <div className="icon"></div>
        <h1>
          Do you have a<br />
          web-surfing habit?
        </h1>

        <p>
          FocusLite is a schedule-based website blocker for your Mac.
          <br />
          Regain your ability to hyper-focus!
        </p>

        <div className="screenshot">
          <div className="image"></div>
        </div>

        <div className="actions">
          <a href={createAppStoreLink("1515566936")} className="button download">
            <Apple />
            <span>Download</span>
          </a>
          <a
            href="https://www.youtube.com/watch?v=JmsWDqO7APM"
            target="_blank"
            className="button"
          >
            <PlayCircled />
            <span>Play video</span>
          </a>
        </div>
      </div>

      <div className="section">
        <h2>Create a schedule</h2>

        <p>
          Decide when and how long you’ll spend online. Block Twitter for your WFH time.
          Or set a budget for news-browsing. Set your rules and stick with them.
        </p>

        <div className="screenshot schedule"></div>
      </div>

      <div className="section">
        <h2>Commit to the rules</h2>

        <p>
          Will you commit to your rules? Let’s play a game. Pay $5.99 to start a streak.
          Your rules will now be enforced, except by deleting the app. If you do that,
          you’ll lose your fee.
        </p>

        <p className="small">
          (You can still use FocusLite for free, this purchase is optional.)
        </p>

        <div className="screenshot streak"></div>
      </div>

      <div className="section">
        <h2>Network-based blocking</h2>

        <p>
          FocusLite uses a firewall running from system extension. This approach is safe
          and private, and keeps websites blocked in all browsers and apps.
        </p>
      </div>

      <div className="divider"></div>

      <div className="signup">
        <p>
          We build simple tools that help you be intentional about technology. Let's stay
          in touch through our monthly digest.
        </p>

        {/* <%= render :partial => "shared/subscribe_form", :locals => {
    :class_name => "newsletter",
    :list => "apps_newsletter",
    :source => "focuslite_landing_modern",
    :redirect_path => focuslite_index_path,
     } %> */}
        <SignupForm />

        <p className="small">
          You are signing up to receive a short email digest every month. You can
          unsubscribe at any time. Your email will never be shared with anyone.
        </p>

        <p className="small">
          FocusLite is made by{" "}
          <a target="_blank" href="https://twitter.com/_vojto">
            Vojtech Rinik
          </a>
          , a programmer who likes to build productivity apps. Check out also{" "}
          <a target="_blank" href="https://tomato2.app">
            Tomato 2
          </a>{" "}
          and{" "}
          <a target="_blank" href="http://focuslist.co">
            FocusList
          </a>
          .
        </p>
        <p className="small"></p>
      </div>
    </main>
  )
}

export default Index
